import React, {useEffect, useState} from "react";
import {RadioGroup} from "@headlessui/react";
import classNames from "classnames";
import OneUserIcon from "../../../components/Icons/OneUserIcon";
// import ManyUserIcon from "../../../components/Icons/ManyUserIcon";
import CalendarIcon from "../../../components/Icons/CalendarIcon";
import {ExclamationCircleIcon, ExclamationIcon, PlusIcon, XCircleIcon,} from "@heroicons/react/solid";
import {Formik, useFormikContext} from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import api from "../../../services/api";
import useGlobal from "../../../state/useGlobal";
import {useToasts} from "react-toast-notifications";
import "react-dates/initialize";
import {SingleDatePicker} from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import "moment/locale/de";
import "moment/locale/fr";
import "moment/locale/pt";
import {useTranslation} from "react-i18next";
import NumStudentSlider from "../NumStudentSlider";
import {STORE_ID_TO_CODE} from "../../../constants/storeCode";

const ValidateComponent = () => {
  const { isValid } = useFormikContext();
  /* eslint-disable no-unused-vars */
  const [globalState, globalActions] = useGlobal();

  React.useEffect(() => {
    globalActions.setSubmitGroupOrder({ isValid });
  }, [isValid, globalActions]);
  return null;
};


export default function SettingsStep({ bindSubmitForm }) {
  const { t, i18n } = useTranslation();
  const [nameClass, setNameClass] = useState("");
  /* eslint-disable no-unused-vars */
  const [globalState, globalActions] = useGlobal();
  const { groupOrder } = globalState;
  const { school_code, number_students, classes, deadline, items, status } = groupOrder;
  const { addToast } = useToasts();
  const [calendar, setCalendar] = useState([])

  const [focused, setFocused] = React.useState();

  moment.locale(i18n.language);

  const defaultDeadlineMinDate = () => {
    let result = new Date();
    result.setDate(result.getDate() + 1);

    return result;
  }

  useEffect(async () => {
    const { data } = await api.getByStore(`/group-orders/calendar/`, STORE_ID_TO_CODE[globalState.store]);
    const afterToday = data.filter((item) => moment(item.date).isAfter(moment()));

    setCalendar(afterToday);
  }, []);

  return (
    <div className="pt-4 pb-14 md:py-8 ">
      {items.length === 0 ? (
        <div className="p-4 mb-4 border-l-4 border-yellow-400 bg-yellow-50">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationIcon
                className="w-5 h-5 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm text-yellow-700">
                {t(
                  "You must add at least 1 product to activate this Group Order."
                )}
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <h3 className="text-xl font-bold md:text-2xl ">
        {t("Konfiguriere Deine Sammelbestellung")}:
      </h3>
      <div className="mt-8">
        <Formik
          validateOnMount
          enableReinitialize
          initialValues={{
            deadline: deadline ? moment(deadline) : null,
            name: groupOrder.name ? groupOrder.name : "",
            packageType: groupOrder.package ? groupOrder.package : "0",
            numberStudents: parseInt(number_students ?? "0"),
            listClass: classes,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required(t("Erforderlich")),
            deadline: Yup.string().nullable().required(t("Erforderlich")),
            packageType: Yup.string().required(t("Erforderlich")),
            numberStudents: Yup.number().integer().min(1, t("Erforderlich")).required(t("Erforderlich")),
            listClass: Yup.array().min(1, t("Erforderlich")),
          })}
          onSubmit={async (values) => {
            const dataPayload = {
              package: values.packageType,
              classes: values.listClass,
              deadline: dayjs(values.deadline).format("YYYY-MM-DD"),
              number_students: values.numberStudents,
              name: values.name,
            };

            try {
              let groupOrderData = await api.post(
                `/group-orders/${school_code}`,
                {},
                dataPayload
              );

              const { data } = groupOrderData;
              const { success, message, payload } = data;

              if (success) {
                globalActions.setGroupOrder(payload);
              }

              return success;
            } catch (errorResponse) {
              const { data } = errorResponse;
              const { message } = data;
              addToast(message, { appearance: "error" });
            }

            return false;
          }}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleSubmit,
            submitForm,
          }) => {
            bindSubmitForm(submitForm);
            return (
              <form noValidate onSubmit={handleSubmit}>
                <ValidateComponent />
                <div className="md:grid md:grid-cols-5 md:gap-12">
                  <div className="md:col-span-2">
                    <h3 className="space-x-2 text-base leading-6 md:text-lg text-cal-primary-gunmetal-black-202833">
                      <span>
                        {t("1. Wie sollen wir die Sammelbestellung verpacken?")}
                      </span>
                      <span className=" text-cal-secondary-fiery-rose-f74e6c">
                        *
                      </span>
                    </h3>
                    <div className="w-full max-w-md mx-auto mt-2 sm:mt-8">
                      <RadioGroup
                        value={values.packageType}
                        onChange={(e) => setFieldValue("packageType", e, true)}
                      >
                        <div className="space-y-4">
                          <RadioGroup.Option
                            value="0"
                            className={({ active }) =>
                              classNames(
                                "relative block rounded-lg border-2 border-gray-300 bg-cal-primary-floral-white-FFFEFC shadow-sm px-6 py-4 cursor-pointer hover:border-gray-400 sm:flex sm:justify-between focus:outline-none h-24 ",
                                {
                                  "ring-1 ring-offset-2 ring-cal-primary-celadon-green-00B5B1":
                                    active,
                                }
                              )
                            }
                          >
                            {({ checked }) => (
                              <>
                                <div className="flex items-center h-full space-x-4 ">
                                  <div>
                                    <OneUserIcon
                                      className="w-10 md:w-12 "
                                      activeIcon={checked}
                                    />
                                  </div>
                                  <div className="text-sm">
                                    <RadioGroup.Label
                                      as="p"
                                      className={classNames(
                                        " text-sm md:text-base lg:text-lg font-medium  ",
                                        {
                                          "text-cal-primary-celadon-green-00B5B1":
                                            checked,
                                          "text-cal-primary-gunmetal-black-989898":
                                            !checked,
                                        }
                                      )}
                                    >
                                      {t("einzeln verpackt")}
                                    </RadioGroup.Label>
                                    <RadioGroup.Description
                                      as="div"
                                      className={classNames("flex flex-col", {
                                        "text-cal-primary-celadon-green-00B5B1":
                                          checked,
                                        "text-cal-primary-gunmetal-black-989898":
                                          !checked,
                                      })}
                                    >
                                      <p className="sm:inline">
                                        {t("labeled by name")}
                                      </p>
                                      <p className="sm:inline">
                                        {t("delivered by class")}
                                      </p>
                                    </RadioGroup.Description>
                                  </div>
                                </div>
                                <div
                                  className={classNames(
                                    "absolute -inset-px rounded-lg border-2 pointer-events-none",
                                    {
                                      "border-cal-primary-celadon-green-00B5B1":
                                        checked,
                                      "border-transparent": !checked,
                                    }
                                  )}
                                  aria-hidden="true"
                                />
                              </>
                            )}
                          </RadioGroup.Option>
                          {/* <RadioGroup.Option
                            value="1"
                            className={({ active }) =>
                              classNames(
                                "relative block rounded-lg border-2 border-gray-300 bg-cal-primary-floral-white-FFFEFC shadow-sm px-6 py-4 cursor-pointer hover:border-gray-400 sm:flex sm:justify-between focus:outline-none h-24",
                                {
                                  "ring-1 ring-offset-2 ring-cal-primary-celadon-green-00B5B1":
                                    active,
                                }
                              )
                            }
                          >
                            {({ checked }) => (
                              <>
                                <div className="flex items-center h-full space-x-4 ">
                                  <div>
                                    <ManyUserIcon
                                      className="w-10 md:w-12"
                                      activeIcon={checked}
                                    />
                                  </div>
                                  <div className="text-sm">
                                    <RadioGroup.Label
                                      as="p"
                                      className={classNames(
                                        " text-sm md:text-base lg:text-lg font-medium  ",
                                        {
                                          "text-cal-primary-celadon-green-00B5B1":
                                            checked,
                                          "text-cal-primary-gunmetal-black-989898":
                                            !checked,
                                        }
                                      )}
                                    >
                                      {t("klassenweise verpackt")}
                                    </RadioGroup.Label>
                                    <RadioGroup.Description
                                      as="div"
                                      className={classNames("", {
                                        "text-cal-primary-celadon-green-00B5B1":
                                          checked,
                                        "text-cal-primary-gunmetal-black-989898":
                                          !checked,
                                      })}
                                    >
                                      <p className="sm:inline">
                                        {t("weniger Plastik")}
                                      </p>
                                    </RadioGroup.Description>
                                  </div>
                                </div>
                                <div
                                  className={classNames(
                                    "absolute -inset-px rounded-lg border-2 pointer-events-none",
                                    {
                                      "border-cal-primary-celadon-green-00B5B1":
                                        checked,
                                      "border-transparent": !checked,
                                    }
                                  )}
                                  aria-hidden="true"
                                />
                              </>
                            )}
                          </RadioGroup.Option> */}
                        </div>
                      </RadioGroup>
                      {errors.packageType && (
                        <div className="flex items-center mt-4">
                          <span>
                            <XCircleIcon
                              className="w-5 h-5 text-red-500"
                              aria-hidden="true"
                            />
                          </span>
                          <span className="ml-2 text-sm text-red-600 ">
                            {errors.packageType}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mt-5 md:pl-12 md:mt-0 md:col-span-3 md:border-l-2">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6">
                        <h3 className="mb-2 sm:mb-6 space-x-2 text-base leading-6 md:text-lg text-cal-primary-gunmetal-black-202833">
                          <span>{t("2. Sammelbestellung einen Namen")}:</span>
                          <span className=" text-cal-secondary-fiery-rose-f74e6c">
                            *
                          </span>
                        </h3>
                        <div className="relative mt-1 rounded-md shadow-sm">
                          <input
                            type="text"
                            className={classNames(
                              "block w-full text-sm border-2 rounded-md bg-cal-primary-floral-white-FFFEFC md:text-base",
                              {
                                "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md":
                                  errors.name,
                                "border-gray-300 focus:ring-cal-primary-celadon-green-00B5B1 focus:border-cal-primary-celadon-green-00B5B1 ":
                                  !errors.name,
                              }
                            )}
                            name="name"
                            value={values.name ? values.name : ""}
                            onChange={(e) =>
                              setFieldValue("name", e.target.value, true)
                            }
                            placeholder={t("Bitte eingeben")}
                          />
                          {errors.name && (
                            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                              <ExclamationCircleIcon
                                className="w-5 h-5 text-red-500"
                                aria-hidden="true"
                              />
                            </div>
                          )}
                        </div>
                        {errors.name && (
                          <div className="flex items-center mt-4">
                            <span>
                              <XCircleIcon
                                className="w-5 h-5 text-red-500"
                                aria-hidden="true"
                              />
                            </span>
                            <span className="ml-2 text-sm text-red-600 ">
                              {errors.name}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="col-span-6">
                        <h3 className="mb-2 sm:mb-6 space-x-2 text-base leading-6 md:text-lg text-cal-primary-gunmetal-black-202833">
                          <span>
                            {t("3. Nenn uns Dein bevorzugtes Fälligkeitsdatum")}
                            :
                          </span>
                          <span className=" text-cal-secondary-fiery-rose-f74e6c">
                            *
                          </span>
                        </h3>
                        <SingleDatePicker
                            isOutsideRange={(day) => day.isBefore(defaultDeadlineMinDate())}
                            displayFormat={t("DD.MM.YYYY")}
                            readOnly
                            disabled={status === 'expired' && deadline}
                            verticalSpacing={8}
                            hideKeyboardShortcutsPanel
                            numberOfMonths={1}
                            customInputIcon={
                                <div className="relative">
                                      <span className={classNames({
                                        'w-2 h-2 absolute rounded-3xl -right-2 top-0 bg-green-500': values.deadline,
                                        'bg-yellow-500': calendar.find((item) => item.date === values.deadline?.format("YYYY-MM-DD") && item.color === 'yellow'),
                                        'bg-orange-500': calendar.find((item) => item.date === values.deadline?.format("YYYY-MM-DD") && item.color === 'orange'),
                                      })}></span>
                                  <CalendarIcon className="w-8 h-8 "/>
                                </div>
                            }
                            date={values.deadline}
                            onDateChange={(date) =>
                                setFieldValue("deadline", date, true)
                            }
                            focused={focused}
                            onFocusChange={({focused}) => setFocused(focused)}
                            renderCalendarInfo={() => {
                              return (
                                <>
                                  <div className="relative px-4 py-3 flex items-baseline -mt-5 gap-2">
                                    <span className="mt-1 w-1.5 h-1.5 absolute bg-green-500 rounded-3xl"></span>
                                    <div>
                                      <div className="text-xs font-bold text-gray-500 pl-3">{t("normale Auslastung")}</div>
                                      <div className="mt-1 text-xs text-gray-500 pl-3">{t("Pünktliche Bearbeitung")}</div>
                                    </div>
                                  </div>

                                  <div className="relative px-4 py-3 flex items-baseline -mt-3 gap-2">
                                    <span className="mt-1 w-1.5 h-1.5 absolute bg-yellow-500 rounded-3xl"></span>
                                    <div>
                                      <div className="text-xs font-bold text-gray-500 pl-3">{t("mittlere Auslastung")}</div>
                                      <div className="mt-1 text-xs text-gray-500 pl-3">{t("Bearbeitung kann sich um 2-3 Tage verzögern")}</div>
                                    </div>
                                  </div>

                                  <div className="relative px-4 py-3 flex items-baseline -mt-3 gap-2">
                                    <span className="mt-1 w-1.5 h-1.5 absolute bg-orange-500 rounded-3xl"></span>
                                    <div>
                                      <div className="text-xs font-bold text-gray-500 pl-3">{t("hohe Auslastung")}</div>
                                      <div className="mt-1 text-xs text-gray-500 pl-3">{t("Bearbeitung kann sich um 4-6 Tage verzögern")}</div>
                                    </div>
                                  </div>
                                </>
                              )
                            }}
                            renderDayContents={(day) => {
                              // If date in calendar [{date: '2023-01-04', color: 'red'}] set color
                              const date = calendar.find((item) => item.date === day.format("YYYY-MM-DD"));
                              if (date) {
                                return (
                                    <div className="relative">
                                      <span className={classNames({
                                        'w-1 h-1 absolute rounded-3xl right-0.5 -top-1 bg-green-500': true,
                                        'bg-yellow-500': date.color === 'yellow',
                                        'bg-orange-500': date.color === 'orange',
                                      })}></span>
                                      {day.format("D")}
                                    </div>
                                )
                              }
                              if (day.isAfter(moment().add(1, 'days'))) {
                                return (
                                    <div className="relative">
                                      <span className={classNames({
                                        'w-1 h-1 absolute rounded-3xl right-0.5 -top-1 bg-green-500': true,
                                      })}></span>
                                      {day.format("D")}
                                    </div>
                                )
                              }
                              return day.date();
                            }}
                        />

                        {errors.deadline && (
                            <div className="flex items-center mt-4">
                            <span>
                              <XCircleIcon
                                  className="w-5 h-5 text-red-500"
                                  aria-hidden="true"
                              />
                            </span>
                            <span className="ml-2 text-sm text-red-600 ">
                              {errors.deadline}
                            </span>
                          </div>
                        )}
                      </div>

                      <div className="col-span-6 mt-4">
                        <h3 className="mb-2 sm:mb-6 space-x-2 text-base leading-6 md:text-lg text-cal-primary-gunmetal-black-202833">
                          <span>
                            {t(
                              "4. Für welche Klassen ist die Sammelbestellung?"
                            )}
                          </span>
                          <span className=" text-cal-secondary-fiery-rose-f74e6c">
                            *
                          </span>
                        </h3>
                        <div className="flex mt-1 rounded-md shadow-sm">
                          <div className="relative flex items-stretch flex-grow focus-within:z-10">
                            <input
                              type="text"
                              name="nameClass"
                              id="nameClass"
                              value={nameClass}
                              onChange={(e) => setNameClass(e.target.value)}
                              onKeyPress={(event) => {
                                if (event.charCode === 13) {
                                  event.preventDefault();
                                  if (event.target.value.trim()) {
                                    setNameClass(event.target.value.trim());
                                    setFieldValue(
                                      "listClass",
                                      [
                                        ...new Set([
                                          ...values.listClass,
                                          nameClass.trim(),
                                        ]),
                                      ],
                                      true
                                    );
                                    setNameClass("");
                                  }
                                }
                              }}
                              className="block w-full text-sm border-2 border-gray-300 rounded-none bg-cal-primary-floral-white-FFFEFC focus:ring-cal-primary-celadon-green-00B5B1 focus:border-cal-primary-celadon-green-00B5B1 rounded-l-md md:text-base"
                              placeholder={t("Name eingeben und Enter drücken")}
                              autoComplete="off"
                            />
                          </div>
                          <button
                            type="button"
                            onClick={() => {
                              if (nameClass.trim()) {
                                setFieldValue(
                                  "listClass",
                                  [
                                    ...new Set([
                                      ...values.listClass,
                                      nameClass.trim(),
                                    ]),
                                  ],
                                  true
                                );
                                setNameClass("");
                              }
                            }}
                            className={classNames(
                              "relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium border-2 md:text-base rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none ",
                              {
                                "text-cal-primary-celadon-green-00B5B1 border-cal-primary-celadon-green-00B5B1 ":
                                  nameClass,
                                "text-cal-primary-gunmetal-black-989898 border-gray-300":
                                  !nameClass,
                              }
                            )}
                          >
                            <PlusIcon
                              className={classNames("w-5 h-5 ", {
                                "text-cal-primary-celadon-green-00B5B1 ":
                                  nameClass,
                                "text-gray-400": !nameClass,
                              })}
                              aria-hidden="true"
                            />
                            <span>{t("weitere")}</span>
                          </button>
                        </div>
                        <div className="mt-4 space-x-2">
                          {values.listClass.map((nameClass) => (
                            <span
                              key={nameClass}
                              className="inline-flex rounded-full items-center py-0.5 mt-2 pl-2.5 pr-2 text-sm  bg-cal-secondary-fiery-rose-f74e6c text-cal-primary-floral-white-FEFCF6"
                            >
                              {nameClass}
                              <button
                                type="button"
                                onClick={() => {
                                  const filteredItems = values.listClass.filter(
                                    (item) => item !== nameClass
                                  );
                                  setFieldValue(
                                    "listClass",
                                    filteredItems,
                                    true
                                  );
                                }}
                                className="inline-flex items-center justify-center flex-shrink-0 w-4 h-4 ml-1 rounded-full text-cal-primary-floral-white-FEFCF6 hover:bg-cal-primary-floral-white-FEFCF6 hover:text-cal-secondary-fiery-rose-f74e6c focus:outline-none focus:bg-cal-primary-floral-white-FEFCF6 focus:text-cal-secondary-fiery-rose-f74e6c"
                              >
                                <svg
                                  className="w-2 h-2"
                                  stroke="currentColor"
                                  fill="none"
                                  viewBox="0 0 8 8"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeWidth="1.5"
                                    d="M1 1l6 6m0-6L1 7"
                                  />
                                </svg>
                              </button>
                            </span>
                          ))}
                        </div>
                        {errors.listClass && (
                          <div className="flex items-center mt-4">
                            <span>
                              <XCircleIcon
                                className="w-5 h-5 text-red-500"
                                aria-hidden="true"
                              />
                            </span>
                            <span className="ml-2 text-sm text-red-600 ">
                              {errors.listClass}
                            </span>
                          </div>
                        )}
                      </div>

                      <div className="col-span-6 mt-4">
                        <h3 className="mb-6 space-x-2 text-base leading-6 md:text-lg text-cal-primary-gunmetal-black-202833 ">
                          <span>
                            {t(
                              "5. Wie viele Schüler werden voraussichtlich bestellen?"
                            )}
                          </span>
                          <span className=" text-cal-secondary-fiery-rose-f74e6c">
                            *
                          </span>
                        </h3>
                        <div className={"mb-7 px-2 text-center"}>
                          <NumStudentSlider
                              defaultValue={values.numberStudents}
                              onChange={function (value) {
                                setFieldValue("numberStudents", value, true);
                              }}
                          />
                          <div
                              className={"rounded-md border-gray-300 inline-block p-2 mt-6 bg-cal-primary-floral-white-FFFEFC border-2"}>
                            <span className={"text-cal-primary-gunmetal-black-202833"}>
                              {values.numberStudents}
                            </span>
                          </div>
                        </div>
                        {errors.numberStudents && (
                          <div className="flex items-center mt-4">
                            <span>
                              <XCircleIcon
                                className="w-5 h-5 text-red-500"
                                aria-hidden="true"
                              />
                            </span>
                            <span className="ml-2 text-sm text-red-600 ">
                              {errors.numberStudents}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}
