import React, { Component } from "react";
import StepsModalLayout from "../../components/Layouts/StepsModalLayout";
import Loading from "../HomeScreen/Loading";
import { withRouter } from "react-router";
import { connect } from "../../state/useGlobal";
import api from "../../services/api";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";
import { withToastManager } from "react-toast-notifications";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import OrderStatusScreen from "../OrderStatusScreen/index";
import {sendPageToGA} from "../../services/ga4";

class OrderOverviewScreen extends Component {
  constructor(props) {
    super(props);
    const id = this.props.match.params.id;

    this.state = {
      isLoading: true,
      isLoadingPrint: false,
      schoolCode: id,
      orders: [],
      total: 0,
    };
  }

  async componentDidMount() {
    const { schoolCode } = this.state;
    // Load async data.
    let overviewData = await api.get(`/group-orders/${schoolCode}/overview`);

    const { data } = overviewData;
    const { success, payload } = data;
    if (success) {
      const { orders, total } = payload;
      this.setState({
        total,
        orders,
        isLoading: false,
      });
    }

    sendPageToGA('/calcuso-group-order-teacher-order-overview', 'GO Teacher | Order overview');
  }

  handleExportAll = async () => {
    const { schoolCode } = this.state;
    const { toastManager } = this.props;
    this.setState({ isLoadingPrint: true });

    let printData = await api.post(
      `/group-orders/${schoolCode}/overview/export`
    );

    const { data } = printData;
    const { success, payload, message } = data;
    const { url } = payload;

    this.setState({ isLoadingPrint: false });

    toastManager.add(message, {
      appearance: success ? "success" : "error",
      autoDismiss: true,
    });

    if (success) {
      window.open(url, "_blank");
    }
  };

  render() {
    const { isLoading, schoolCode, orders, total, isLoadingPrint } = this.state;
    const { t } = this.props;
    const nameClasses = Object.keys(orders);
    return (
      <StepsModalLayout schoolCode={schoolCode}>
        <div className="h-full px-2 mt-5 mb-16 md:py-5 ">
          <div className="flex flex-col items-center justify-between mt-4 mb-4 md:mt-20 md:flex-row">
            <h3 className="text-xl font-medium md:text-2xl text-cal-primary-gunmetal-black-202833">
              {t("Bestellungsübersicht")} ({total})
            </h3>
            {total > 0 ? (
              <button
                disabled={isLoadingPrint}
                type="button"
                onClick={this.handleExportAll}
                className={classNames(
                  "hidden md:inline-flex mt-4 md:mt-0 items-center px-2 py-1 md:px-6 md:py-3 space-x-4 text-sm md:text-base font-medium bg-white border-2 rounded-md shadow-sm border-cal-primary-celadon-green-01A7A3 text-cal-primary-celadon-green-01A7A3 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cal-primary-celadon-green-01A7A3 transition duration-500 ease-in-out transform hover:translate-2 hover:scale-110",
                  {
                    "opacity-60": isLoadingPrint,
                  }
                )}
              >
                <svg
                  id="Icon_Print_48"
                  data-name="Icon Print 48"
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-8 h-8 "
                  viewBox="0 0 48 48"
                >
                  <rect
                    id="_48_x_48"
                    data-name="48 x 48"
                    width="48"
                    height="48"
                    transform="translate(0 0)"
                    fill="none"
                  />
                  <g
                    id="Group_658"
                    data-name="Group 658"
                    transform="translate(1 5)"
                  >
                    <path
                      id="Path_250"
                      data-name="Path 250"
                      d="M46.989,1733.843h-10v-26h46v26h-9"
                      transform="translate(-36.989 -1701.843)"
                      fill="none"
                      stroke="#01a7a3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <rect
                      id="Rectangle_503"
                      data-name="Rectangle 503"
                      width="27"
                      height="12"
                      transform="translate(10 26)"
                      fill="none"
                      stroke="#01a7a3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <path
                      id="Path_251"
                      data-name="Path 251"
                      d="M43.989,1707.843v-6h32v6"
                      transform="translate(-36.989 -1701.843)"
                      fill="none"
                      stroke="#01a7a3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <circle
                      id="Ellipse_70"
                      data-name="Ellipse 70"
                      cx="1"
                      cy="1"
                      r="1"
                      transform="translate(5 13)"
                      fill="none"
                      stroke="#01a7a3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <line
                      id="Line_150"
                      data-name="Line 150"
                      x2="8"
                      transform="translate(34 12)"
                      fill="none"
                      stroke="#01a7a3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <line
                      id="Line_151"
                      data-name="Line 151"
                      x2="8"
                      transform="translate(34 16)"
                      fill="none"
                      stroke="#01a7a3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <circle
                      id="Ellipse_71"
                      data-name="Ellipse 71"
                      cx="1"
                      cy="1"
                      r="1"
                      transform="translate(11 13)"
                      fill="none"
                      stroke="#01a7a3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                  </g>
                </svg>
                {isLoadingPrint ? (
                  <span>{t("wird bearbeitet ...")}</span>
                ) : (
                  <span>{t("Übersicht drucken")}</span>
                )}
              </button>
            ) : (
              ""
            )}
          </div>

          {isLoading ? (
            <Loading />
          ) : (
            <>
              {total > 0 ? (
                Object.values(orders).map((nameClass, key) => (
                  <Disclosure key={key} as="div" className="mt-2">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-xs font-medium text-left rounded-lg md:text-sm text-cal-primary-celadon-green-008E8D bg-cal-primary-celadon-green-E5F8F7 hover:bg-cal-primary-celadon-green-80DAD8 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                          <span>
                            {nameClasses[key]} ({nameClass.length}{" "}
                            {t("bestellungen")})
                          </span>
                          <ChevronUpIcon
                            className={`${
                              open ? "transform rotate-180" : ""
                            } w-5 h-5 text-cal-primary-celadon-green-008E8D`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="py-4 mx-auto">
                          <div className="hidden w-full overflow-hidden border-b border-gray-200 shadow md:inline-block sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                  >
                                    {t("Name des Studenten")}
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                  >
                                    {t("Bestellnummer")}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {nameClass.map((order, orderIdx) => {
                                  return (
                                    <tr
                                      key={order.increment_id}
                                      className={
                                        orderIdx % 2 === 0
                                          ? "bg-white"
                                          : "bg-gray-50"
                                      }
                                    >
                                      <td className="px-6 py-4 text-sm text-cal-primary-gunmetal-black-202833 whitespace-nowrap">
                                        {order.school_name}
                                      </td>
                                      <td className="px-6 py-4 text-sm text-cal-primary-gunmetal-black-989898 whitespace-nowrap">
                                        {order.increment_id}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                          <div className="block overflow-hidden bg-white rounded-md shadow md:hidden">
                            <ul className="divide-y-2 divide-cal-primary-floral-white-FEF9EF">
                              {nameClass.map((order, orderIdx) => (
                                <li
                                  key={order.increment_id}
                                  className="px-6 py-4"
                                >
                                  <div className="flex items-center justify-between">
                                    <p>{order.school_name}</p>
                                    <p className=" text-cal-primary-gunmetal-black-989898">
                                      {order.increment_id}
                                    </p>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))
              ) : (
                <h3 className="mt-2">{t("Keine Gegenstände")}</h3>
              )}
            </>
          )}
        </div>
        {total > 0 ? (
          <div className="fixed inset-x-0 bottom-0 z-50 md:hidden ">
            <div className="h-16 bg-cal-primary-floral-white-F5F0E1">
              <div className="flex flex-col items-center justify-center h-full px-6 py-3 mx-auto">
                <div className="flex justify-center w-full ">
                  <button
                    disabled={isLoadingPrint}
                    type="button"
                    onClick={this.handleExportAll}
                    className={classNames(
                      "inline-flex md:mt-0 items-center px-2 py-1 md:px-6 md:py-3 space-x-4 text-sm md:text-base font-medium bg-white border-2 rounded-md shadow-sm border-cal-primary-celadon-green-01A7A3 text-cal-primary-celadon-green-01A7A3 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cal-primary-celadon-green-01A7A3 transition duration-500 ease-in-out transform hover:translate-2 hover:scale-110",
                      {
                        "opacity-60": isLoadingPrint,
                      }
                    )}
                  >
                    <svg
                      id="Icon_Print_48"
                      data-name="Icon Print 48"
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-8 h-8 "
                      viewBox="0 0 48 48"
                    >
                      <rect
                        id="_48_x_48"
                        data-name="48 x 48"
                        width="48"
                        height="48"
                        transform="translate(0 0)"
                        fill="none"
                      />
                      <g
                        id="Group_658"
                        data-name="Group 658"
                        transform="translate(1 5)"
                      >
                        <path
                          id="Path_250"
                          data-name="Path 250"
                          d="M46.989,1733.843h-10v-26h46v26h-9"
                          transform="translate(-36.989 -1701.843)"
                          fill="none"
                          stroke="#01a7a3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        />
                        <rect
                          id="Rectangle_503"
                          data-name="Rectangle 503"
                          width="27"
                          height="12"
                          transform="translate(10 26)"
                          fill="none"
                          stroke="#01a7a3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        />
                        <path
                          id="Path_251"
                          data-name="Path 251"
                          d="M43.989,1707.843v-6h32v6"
                          transform="translate(-36.989 -1701.843)"
                          fill="none"
                          stroke="#01a7a3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        />
                        <circle
                          id="Ellipse_70"
                          data-name="Ellipse 70"
                          cx="1"
                          cy="1"
                          r="1"
                          transform="translate(5 13)"
                          fill="none"
                          stroke="#01a7a3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        />
                        <line
                          id="Line_150"
                          data-name="Line 150"
                          x2="8"
                          transform="translate(34 12)"
                          fill="none"
                          stroke="#01a7a3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        />
                        <line
                          id="Line_151"
                          data-name="Line 151"
                          x2="8"
                          transform="translate(34 16)"
                          fill="none"
                          stroke="#01a7a3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        />
                        <circle
                          id="Ellipse_71"
                          data-name="Ellipse 71"
                          cx="1"
                          cy="1"
                          r="1"
                          transform="translate(11 13)"
                          fill="none"
                          stroke="#01a7a3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        />
                      </g>
                    </svg>
                    {isLoadingPrint ? (
                      <span>{t("wird bearbeitet ...")}</span>
                    ) : (
                      <span>{t("Übersicht drucken")}</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <OrderStatusScreen/>
      </StepsModalLayout>
    );
  }
}

export default withToastManager(
  withRouter(connect(withTranslation()(OrderOverviewScreen)))
);
